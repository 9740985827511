import { Fragment } from "react";
import { Accordion } from "react-bootstrap";
import AccordionItem from "../common/ui/AccordionItem";
import MarkdownFile from "../common/ui/MarkdownFile";

const Background = () => {
  return (
    <Fragment>
      <Accordion defaultActiveKey="0" className="mb-2" alwaysOpen>
        <AccordionItem header="Where do the data come from?" eventKey="0">
          <MarkdownFile filePath="/markdown/backgroundPage/dataSources.md" />
        </AccordionItem>
        {/* <AccordionItem header="What is the Effort Sharing System?" eventKey="1">
          <MarkdownFile filePath="/markdown/backgroundPage/dataSources.md" />
        </AccordionItem> */}
        <AccordionItem header="Where are the NACE codes are coming from?" eventKey="1">
          <MarkdownFile filePath="/markdown/faq/nace.md" />
        </AccordionItem>
        <AccordionItem header="Can I analyze data at the firm level?" eventKey="2">
          <MarkdownFile filePath="/markdown/faq/firmlevel.md" />
        </AccordionItem>
        <AccordionItem header="Are all transactions observed?" eventKey="4">
          <MarkdownFile filePath="/markdown/faq/all_transactions.md" />
        </AccordionItem>   
        <AccordionItem header="Can I calculate the allowance holding at the account level?" eventKey="5">
          <MarkdownFile filePath="/markdown/faq/banking.md" />
        </AccordionItem>                   
        <AccordionItem header="How can I analyze auctions?" eventKey="6">
          <MarkdownFile filePath="/markdown/faq/auctioning.md" />
        </AccordionItem>    
        <AccordionItem header="I want to analyze transfers only between operator and person holding accounts. How to filter the transactions?" eventKey="7">
          <MarkdownFile filePath="/markdown/faq/oha_pha_transfers.md" />
        </AccordionItem>               
      </Accordion>
    </Fragment>
  );
};

export default Background;
